import '../../styles/nav.css'
import { AiFillInfoCircle } from 'react-icons/ai'
import { useState, useEffect } from 'react'
import DropDown from './drop-down';
import apiRequest from "../../services/api";
import useWindowDimensions from '../../hooks/useWindowDimensons'

const Nav = () => {

    const { width } = useWindowDimensions()

    const [ dropDown, setDropDown ] = useState<boolean>(false)
    const [ myInfo, setMyInfo ] = useState({})

    useEffect(()=>{
        apiRequest.get('/company/myInfo').then(({ data }) =>{
            setMyInfo(data[0].company)
        })
    }, [])

    const changeIconColor = width! >= 1300 ? '#3d3d3d' : '#ede439'

    return( 
        <>
            <nav className='nav-container'>
                <button onClick={() => setDropDown(!dropDown)} className='nav-btn-info'>
                    <AiFillInfoCircle size={30} color={changeIconColor} onClick={() => setDropDown(!dropDown)} />    
                </button>
            </nav>

            { dropDown && <DropDown myInfo={myInfo} setDropDown={() => setDropDown(!dropDown)} /> }
        </>
            
    )
}

export default Nav