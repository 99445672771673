import { DriverListProvider } from '../services/context'
import AvaliableDrivers from '../components/driversList/avaliable-drivers'

const DriverList = () => {
    return (
        <div className='main-container'>
            <DriverListProvider>
                <AvaliableDrivers />
            </DriverListProvider>
        </div>
    )
}

export default DriverList