import AppRoutes from './routes/routes';
import { GlobalStyle } from './styles/global';

const App = () => {
    return(
        <>
            <AppRoutes />
            <GlobalStyle />
         </>
    )   
}

export default App
