import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Opening from '../pages/opening';
import Login from '../components/paginaInicial/login';
import DriverList from '../pages/driver-list';
import { isAuthenticated } from '../services/auth';
import EsqueceuSenha from '../components/companies/forgotPassword';
import CadastroEmpresa from '../components/companies/companyRegister';
import AvaliableCandidates from '../components/beDriver/avaliable-candidate'
import BeDriverList from '../pages/beDriver';

const PrivateRoute = ({ children }) => {
    const auth = isAuthenticated()
    return auth ? children : <Navigate to='/'></Navigate>
}

const AppRoutes = () => {
    return(
        <BrowserRouter> 
            <Routes>
                <Route path='/' element={<Opening />}/>
                <Route path='/login' element={<Login />} />
                <Route path='/cadastro-empresa' element={<CadastroEmpresa />} />
                                
                {/* Rotas Privadas */}
                <Route path='/inicio' element={<PrivateRoute> <DriverList /> </PrivateRoute>} />
                <Route path='/campanha-motoristas' element={<PrivateRoute> <BeDriverList /> </PrivateRoute>} />                    
            </Routes>
        </BrowserRouter>
    )
}

export default AppRoutes