import '../../../styles/cadastro-empresa-mobile.css'
import '../../../styles/sidebar.css'
import { useState } from "react"
import { Link } from 'react-router-dom'
import { GiMagnifyingGlass } from 'react-icons/gi'
import { ICompanyInfo, IErrorProps } from '../../../services/types'
import apiRequest from "../../../services/api"
import Logo from '../../../images/logo2.png'
import CompanyRegister from './companyRegister'

const CompanyQuery = () => {

    const [ cnpj, setCnpj ] = useState<string>('')
    const [ companyInfo, setCompanyInfo ] = useState<ICompanyInfo>({})

    const [ queryScreen, setQueryScreen ] = useState<boolean>(true)
    const [ registerScreen, setRegisterScreen ] = useState<boolean>(false)
    const [ companyNotFound, setCompanyNotFound ] = useState<boolean>(false)

    const [ errorProps, setErrorProps ] = useState<IErrorProps>({ 
        borderStatus: '#3d3d3d',
    })

    const showRegisterScreen = () => {
        setQueryScreen(false)
        setRegisterScreen(true)
        setCompanyNotFound(false)
    }

    const query = () => {
        if(cnpj == ''){
            setErrorProps({
                borderStatus: '#a21b1b',
                message: 'CNPJ deve ser preenchido'
            })
        }else{
            apiRequest.get(`/company/register/${cnpj}`).then((response) => {
                response.data.map((data: any) => {
                    setCompanyInfo({
                        name: data.name,
                        cnpj: data.cnpj,
                        syndicate: data.syndicate.name
                    })
                })
        
                if(response.status === 200){
                    showRegisterScreen()
                }
            }).catch((error) => {
                if (error.response.status === 404) {
                    setCompanyNotFound(true)
                } else {
                    setErrorProps({
                        borderStatus: '#a21b1b',
                        message: 'Houve algum erro. Tente novamente mais tarde'
                    })
                }
            }) 
        }
    }
    
    return(
        <>
            <div className='responsive-background'>
                <div className='responsive-container'>
                    <div>
                        <img src={Logo} alt='Fetransul Logo' className='img' />
                    </div>
                    {
                    queryScreen &&
                        <>
                            <div className='flex-div title-subtitle'>
                                <h2>Cadastro para empresas</h2>
                                <span>Ao inserir o CNPJ, faremos uma consulta em nosso banco de dados para sabermos se sua empresa está vinculada a um sindicato parceiro.</span>
                            </div>
                            <div className='flex-div input-btn'>
                                    <input 
                                        style={{border: `2px solid ${errorProps.borderStatus}`}} 
                                        placeholder='Insira o cnpj' 
                                        required 
                                        onChange={(event) => {setCnpj(event.target.value)}}
                                    />
                                    <div className='error-msg-container'>
                                        <div className='error-msg'>{errorProps.message}</div>
                                    </div>
                                
                                <div style={{width: '100%'}}>
                                    <button onClick={query} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                        Consultar <GiMagnifyingGlass size={15} />
                                    </button>
                                </div>
                                
                            </div>
                        </>
                    }
                    {registerScreen && <CompanyRegister companyInfo={companyInfo} />}


                    {companyNotFound && <div className='error-msg-container' style={{ flexDirection: 'column', alignItems: 'center' }}>
                                <span className='error-msg'>Identificamos que sua empresa ainda não faz parte do quadro de associados de um dos sindicatos filiados ao Sistema Fetransul. Entre em contato com o sindicato patronal responsável pela sua cidade e faça parte.</span>
                                <h4 className='error-msg'><a href='https://www.fetransul.com.br/sindicatos-filiados/' target='_blank'>CONFIRA A RELAÇÃO.</a></h4>
                            </div>}

                    <span>Já possui um login? <Link className='redirect-btn-to-login' to='/'>Entrar</Link></span>
                </div>   
            </div>

            
        </>
    )
}

export default CompanyQuery