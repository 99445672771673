import logo3 from '../images/logo3.png'

interface IHeaderProps {
    children: JSX.Element
}

const Header = ({ children }: IHeaderProps) => {
    return (
        <div style={{
            width: '90%', 
            flexDirection: 'column', 
            alignItems: 'flex-start',
        }} className='justify-flex'>
            <a href='/'>
                <img src={logo3} style={{width: '280px'}} alt='logo fetransul' />
            </a>
            {children}
        </div>
    )
}

export default Header