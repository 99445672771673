import { FormatarTelefone, FormatarEspecialidade } from '../../../services/utils'
import { Driver, DriverList } from '../../../services/types'


const DesktopTable = ({ driverList }: DriverList) => {
    return( 
        <div className='motora-container'>
        <div style={{width: '90%'}}>
            <h1>Motoristas Disponíveis</h1>
        </div>
        <table className='table'>
            <thead>
                <tr className='thead-row'>
                    <th>Nome</th>
                    <th>Telefone</th>
                    <th>Email</th>
                    <th>Habilitação</th>
                    <th>Especialidade</th>
                    <th>Estado</th>
                    <th>Cidade</th>
                </tr>
            </thead>
            <tbody>
                {driverList.map((driver: Driver)=>{
                    return(
                        <tr className='trow' key={driver.id}>
                            <td>{driver.name}</td>
                            <td>{FormatarTelefone(driver.phone)}</td>
                            <td>{driver.email}</td>
                            <td>{driver.typeCnh.description}</td>
                            <td>{FormatarEspecialidade(driver.specialty)}</td>
                            <td>{driver.state.description}</td>
                            <td>{driver.city.description}</td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
    </div> 
    )
}

export default DesktopTable