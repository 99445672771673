import '../../styles/filters.css';
import { useState, useEffect, useContext } from "react";
import { DriverContext } from "../../services/context";
import { TiposCNH, Especialidades } from "../../services/utils";
import { IDriverListContext } from '../../services/types';
import { IFilters } from '../../services/types'
import apiRequest from "../../services/api";

const Filters = () => {
    
    const { setDriverList, page, setQuantity } = useContext<IDriverListContext>(DriverContext)

    const [ states, setStates ] = useState([])
    const [ cities, setCities ] = useState(0)
    const [ filters, setFilters ] = useState<IFilters[]>([])

    const [ resetState, setResetState] = useState('')
    const [ resetCity, setResetCity ] = useState('')
    const [ resetTypeCNH, setResetTypeCNH ] = useState('')
    const [ resetSpecialty, setResetSpecialty ] = useState('')

    useEffect(()=>{
        apiRequest.get('/states').then((response)=>{
            setStates(response.data.data)
        })
    }, [])

    useEffect(()=>{
        apiRequest.post('/driver/filter', {
            filters,
            filter_join: 'AND',
            page
        }).then((response)=>{
            setDriverList(response.data.data)
            const qt = response.data.meta.total / response.data.meta.per_page
            const rest = response.data.meta.total % response.data.meta.per_page
            const total = qt + (rest > 0 ? 1 : 0)
            setQuantity(total)
        })  
    }, [page, filters])

    const handleFilter = (event: any) =>{
        if(filters.find((item: any) => item.field === event.target.name) === undefined){
            setFilters(filters.concat(createFilter(event)))
        }else{
            setFilters(filters.map((item: any)=>{
                if(item.field === event.target.name){
                    item = createFilter(event)
                }
                return item;
            }))
        }
    }

    const createFilter = (event: any): IFilters => {
        return {
            field: event.target.name,
            operator: 'equals' ,
            param: event.target.value
        }
    }

    const cleanFilter = () => { 
       setFilters([])
       setResetState('')
       setResetCity('')
       setResetTypeCNH('')
       setResetSpecialty('')
       setCities(0)
    }

    return (
        <div className='filters-container'>
                <select
                    name='state'
                    onChange={(event: any)=>{handleFilter(event); setCities(event.target.value); setResetState(event.target.value)}}
                    value={resetState}  
                >
                    <option disabled={true} value=''>Estado</option>
                    {states.map((state: any)=>{
                        return (
                            <option value={state.id} key={state.id}>{state.description}</option>
                        )
                    })}
                </select>
                <select
                    name='city'
                    onChange={(event: any)=>{handleFilter(event); setResetCity(event.target.value)}}
                    value={resetCity}
                >
                    <option disabled={true} value=''>Cidade</option>
                    {states.map((state: any)=>{
                        if(state.id == cities){
                            return state.city.map((city: any)=>(
                                <option value={city.id} key={city.id}>{city.description}</option>
                            ))
                        }
                        
                    })}
                </select>
                <select
                    name='typeCNH'
                    onChange={(event)=>{handleFilter(event); setResetTypeCNH(event.target.value)}}
                    value={resetTypeCNH}
                >
                    <option disabled={true} value=''>Categoria</option>
                    {TiposCNH.map((tipo: any)=>(
                        <option value={tipo.id} key={tipo.id}>{tipo.description}</option>
                    ))}
                </select>
                <select
                    name="specialty"
                    onChange={(event)=>{handleFilter(event); setResetSpecialty(event.target.value)}}
                    value={resetSpecialty}
                >
                    <option selected={true} disabled={true} value=''>Especialidade Complementar</option>
                    {Especialidades.map((especialidade: any)=>(
                        <option value={especialidade.id} key={especialidade.id}>{especialidade.description}</option>
                    ))}
                </select>
                <button onClick={cleanFilter} className='filters-btn'>Limpar Filtros</button>
            </div>
    )

}

export default Filters