import { CandidateListProvider } from '../services/context'
import AvaliableCandidates from '../components/beDriver/avaliable-candidate'

const BeDriverList = () => {
    
    return ( 
        <CandidateListProvider>
            <AvaliableCandidates />
        </CandidateListProvider>
    )

    
}

export default BeDriverList
