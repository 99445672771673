import '../../styles/cadastro-candidato.css';
import 'react-toastify/dist/ReactToastify.css';
import { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Especialidades, TiposCNH } from '../../services/utils';
import apiRequest from '../../services/api';
import InputMask from 'react-input-mask';
import logo2 from '../../images/logo2.png';

const FutureDriver = ({ toggleSelecao }: any) => {

    // const [states, setStates] = useState([])
    // const [city, setCity] = useState('')
    // const [formData, setFormData] = useState<any>({})

    // useEffect(() => {
    //     apiRequest.get('/states').then((response) => {
    //         setStates(response.data.data)
    //     })
    // }, [])

    // const handleFormData = (event: any) => {
    //     const name = event.target.name
    //     formData[name] = event.target.value
    //     setFormData(formData)
    // }

    // const driverRegister = (event: any) => {
    //     event.preventDefault()

    //     formData.cpf = formData.cpf.replace(/\-/, '').replace(/\./, '').replace(/\./, '')
    //     formData.phone = formData.phone.replace(/\(/, '').replace(/\)/, '').replace(/\-/, '')
    //     formData.stateId = parseInt(formData.stateId)
    //     formData.cityId = parseInt(formData.cityId)
    //     formData.typeCnhId = parseInt(formData.typeCnhId)
    //     formData.cnh = parseInt(formData.cnh)
    //     formData.intendedCnh = parseInt(formData.intendedCnh)
    //     formData.specialtyId = parseInt(formData.specialtyId)
    //     if(!formData.email) formData.email = null

    //     apiRequest.post('/candidate/register', formData).then((response) => {

    //         if (response.status === 201) {
    //             toast.success('Agradecemos o seu cadastro em nossa base. Mediante necessidade e demanda de contratação, as empresas parceiras do projeto poderão contatá-lo.', {
    //                 position: "top-right",
    //                 autoClose: 5000,
    //                 hideProgressBar: true,
    //             })
    //             setTimeout(() => {
    //                 document.location.reload()
    //             }, 5000)
    //         } return;

    //     }).catch((error) => {
    //         if (error.status === 500) {
    //             toast.error('Preencha todos os campos do formulário', {
    //                 position: "top-right",
    //                 autoClose: 5000,
    //                 hideProgressBar: true,
    //             });
    //             return;
    //         } else if (error.status === 422){
    //             toast.error('Revise os dados inseridos', {
    //                 position: "top-right",
    //                 autoClose: 5000,
    //                 hideProgressBar: true,
    //             });
    //         }
    //         toast.error('Favor atualize a página e tente novamente', {
    //             position: "top-right",
    //             autoClose: 5000,
    //             hideProgressBar: true,
    //         });
    //         return;
    //     })
    // }

    // return (
    //     <>
    //         <ToastContainer />

    //         <div className='futureDriver-background'>
    //             <div className='futureDriver-div'>
    //                 <a href='/'>
    //                     <img src={logo2} className='futureDriver-img' alt='logo fetransul'></img>
    //                 </a>
    //                 <h4 className='futureDriver-title'>Cadastro para Futuros Motoristas</h4>
    //                 <form onSubmit={driverRegister} className='form-cadastro'>
    //                     <input
    //                         className='futureDriver-input'
    //                         name='name'
    //                         placeholder='Nome Completo'
    //                         onChange={handleFormData}
    //                         autoComplete='off'
    //                         required
    //                     />
    //                     <InputMask
    //                         className='futureDriver-input'
    //                         name='phone'
    //                         placeholder='Telefone'
    //                         onChange={handleFormData}
    //                         mask='(99)99999-9999'
    //                         autoComplete='off'
    //                         required
    //                     />
    //                     <InputMask
    //                         className='futureDriver-input'
    //                         name='cpf'
    //                         placeholder='CPF'
    //                         onChange={handleFormData}
    //                         mask='999.999.999-99'
    //                         autoComplete='off'
    //                         required
    //                     />
    //                     <input
    //                         className='driver-input'
    //                         name='birthDate'
    //                         placeholder='Data de Nascimento'
    //                         onChange={handleFormData}
    //                         onFocus={(e) => (e.target.type = "date")}
    //                         onBlur={(e) => (e.target.type = "text")}
    //                         aria-label='AAAA/MM/DD'
    //                         required
    //                     />
    //                     <input
    //                         className='futureDriver-input'
    //                         name='email'
    //                         placeholder='Email (Opcional)'
    //                         type='email'
    //                         onChange={handleFormData}
    //                     />
    //                     <div className='select-div'>
    //                     <select
    //                             className='futureDriver-input'
    //                             name='cnh'
    //                             onChange={handleFormData}
    //                             required
    //                         >
    //                             <option disabled selected={true}>Possui CNH?</option>
    //                             <option value='1'>Sim</option>
    //                             <option value='0'>Não</option>
    //                         </select>
    //                     </div>
    //                     <div className='select-div'>
    //                     <select
    //                             className='form-select'
    //                             name='specialtyId'
    //                             onChange={handleFormData}
    //                             required
    //                         >
    //                             <option disabled selected={true}>Especialidade complementar</option>
    //                             {Especialidades.map((especialidade) => (
    //                                 <option value={especialidade.id} key={especialidade.id}>{especialidade.description}</option>
    //                             ))}
    //                         </select>
                            
    //                         <select
    //                             className='form-select'
    //                             name='typeCnhId'
    //                             onChange={handleFormData}
    //                             required
    //                         >
    //                             <option disabled selected={true}>Selecione sua CNH</option>
    //                             <option value='1'>A</option>
    //                             <option value='2'>B</option>
    //                             <option value='3'>C</option>
    //                             <option value='4'>D</option>
    //                             <option value='5'>E</option>
    //                             <option value='6'>Não Habilitado</option>
    //                         </select>
    //                     </div>
    //                     <div className='select-div'>
    //                         <select
    //                             className='futureDriver-input'
    //                             name='intendedCnh'
    //                             onChange={handleFormData}
    //                             required
    //                         >
    //                             <option disabled selected={true}>Qual categoria deseja obter?</option>
    //                             {TiposCNH.map((tipo) => (
    //                                 <option value={tipo.id} key={tipo.id}>{tipo.description}</option>
    //                             ))}
    //                         </select>
    //                     </div>
    //                     <div className='select-div'>
    //                         <select
    //                             className='form-select'
    //                             name='stateId'
    //                             onChange={(event) => { setCity(event.target.value); handleFormData(event) }}
    //                             required
    //                         >
    //                             <option selected={true} disabled={true}>Estado</option>
    //                             {states.map((estado: any) => (
    //                                 <option value={estado.id} key={estado.id}>{estado.description}</option>
    //                             ))}
    //                         </select>
    //                         <select
    //                             className='form-select'
    //                             name='cityId'
    //                             onChange={handleFormData}
    //                             required
    //                         >
    //                             <option selected={true} disabled={true}>Cidade</option>
    //                             {states.map((state: any) => {
    //                                 if (state.id == city) {
    //                                     return state.city.map((city: any) => (
    //                                         <option value={city.id} key={city.id}>{city.description}</option>
    //                                     ))
    //                                 }
    //                             })}
    //                         </select>
    //                     </div>
    //                     <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
    //                         <button type='submit' className='futureDriver-btn'>Cadastrar</button>
    //                     </div>
    //                 </form>
    //             </div>
    //         </div>
    //     </>

    return (
        <div style={{color: '#fff'}}>
            <h1>Em breve</h1>
            <h3>Orientações sobre como se tornar um motorista.</h3>
        </div>
    )

    
}

export default FutureDriver