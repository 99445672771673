import { ToastContainer, toast } from 'react-toastify'

export const FormatarData = (date: string): string => {
    const dia = date.substring(0,2)
    const mes = date.substring(3,5)
    const ano = date.substring(6,10)

    const data = `${ano}-${mes}-${dia}`

    return data
}

export const FormatarCNH = (cnhValue: number): string => {
    switch(cnhValue){
        case 0:
            return 'Não'
        case 1:
            return 'Sim'
        default: 
            return 'Não informado'
    }
}

export const FormatarTipoCNH = (typeCnh: number): string => {
    const types = TiposCNH.find((item: any) => item.id == typeCnh)
    if(typeCnh == null || typeCnh == undefined || typeCnh > 5){
        return 'Não Habilitado'
    } else {
        return types?.description!
    }
}

export const FormatarHabilitaçaoPretendida = (typeCnh: number): string => {
    const types = TiposCNH.find((item: any) => item.id == typeCnh)
    if(typeCnh == null || typeCnh == undefined || typeCnh > 5 || typeCnh == 0){
        return 'Não Informado'
    } else {
        return types?.description!
    }
}

export const FormatarEspecialidade = (especialidade: any): string => {
    if( especialidade == null || especialidade == undefined || especialidade > 5){
        return 'Não Possui'
    } else { 
        return especialidade.description
    }
}

export const FormatarTelefone = (telefone: string): string => {
    if(telefone == null) return 'Não informado'
    const ddd = telefone.slice(0,2)
    return `(${ddd})${telefone.substring(2,)}`
}

export const Capitalize = (texto: string): string => {
    
    const splited = texto.split(" ");
      
    for (let i = 0; i < splited.length; i++) {
      splited[i] = splited[i][0].toUpperCase() + splited[i].substring(1);
    }
    
    return splited.join(' ')
    
      
}

export const exceptionHandler = (status: number, code: string) => {
    
    const listStatus = [
        400,
        401,
        402,
        403,
        404,
        409,
        422
    ]

    const error = listStatus.find((item) => item === status)

    if(error) {
        return toast.error(code, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
        })
    }
}

export const TiposCNH = [
    { id: 1, description: 'A' },
    { id: 2, description: 'B' },
    { id: 3, description: 'C' },
    { id: 4, description: 'D' },
    { id: 5, description: 'E' },
]

export const Especialidades = [
    { id: 1, description: 'MOPP' },
    { id: 2, description: 'Carga Indivisível' },
    { id: 3, description: 'Amarração de Cargas' },
    { id: 4, description: 'Direção Defensiva' },
    { id: 6, description: 'Não possui' }
]

export const PossuiCNH = [
    { id: 0, description: 'Não' },
    { id: 1, description: 'Sim' }
]



