import { TailSpin } from "react-loader-spinner";
import { useState } from 'react'

const styles: any = {
    modalContainer: {
        color: '#3d3d3d',
        textAlign: 'center'
    }
}

interface IModal {
    title: string,
    subTitle: string,
    color: string
}

const Modal = ({ title, subTitle, color }: IModal) => {
    return (
        <div style={styles.modalContainer}>
            <h2 style={{ margin: 0, padding: 0, color: color }}>{title}</h2>
            <h4 style={{ margin: 0, padding: 0, color: color }}>{subTitle}</h4>
        </div>
    )
}



const TailSpinLoader = ({ title, subTitle, color }: IModal) => {

    const [ loader, setLoader ] = useState(true)

    setTimeout(() => {
        setLoader(false)
    }, 10000)

    return (
        <>
            { loader == true ? <TailSpin
                height="80"
                width="80"
                color="#3d3d3d"
                ariaLabel="tail-spin-loading"
                radius="1"
                visible={true}
        /> : <Modal title={title} subTitle={subTitle} color={color}/>}
        </>
    )
}

export default TailSpinLoader