//Esse arquivo precisa ser .JSX por causa da lib 'react-responsive-sidebar' que não tem suporte ao Typescript.
import '../../styles/sidebar.css'
import '../../styles/motoristasDisponiveis.css'
import { Sidebar } from 'react-responsive-sidebar'
import { SideBarItems } from '../sidebar/driver-list-sidebar-items'
import useWindowDimensions from '../../hooks/useWindowDimensons'
import DesktopTable from './screens/desktop-screen'
import MobileTable from './screens/mobile-screen'
import Pagination from '../pagination/pagination'
import Nav from '../nav/nav'
import TailSpinLoader from '../loader/tailSpin'

import { useContext } from 'react'
import { DriverContext } from '../../services/context'
const styles = {
    loaderContainer:{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}

const AvaliableDrivers = () => {


    const { driverList, setPage, quantity } = useContext(DriverContext)

    const {width} = useWindowDimensions()

    return (
        <Sidebar
            breakPoint={1300}
            content={SideBarItems}
            background='#3d3d3d'
            width={320}
            closeOnItemSelect={false}
            toggleIconColor='#ede439'
        >
            <Nav />
            { driverList.length > 0 ?

                <>
                    { width >= 1300 ? <DesktopTable driverList={driverList} /> : <MobileTable driverList={driverList} /> }
                    <Pagination quantity={quantity} onSelect={setPage} classContainer='paginator-container-driver'/> 
                </> 
                
                    : 
                <div style={styles.loaderContainer}>
                    <TailSpinLoader 
                        title='Não foram encontrados resultados!'
                        subTitle='Por favor, reveja os filtros aplicados ou recarregue a página.'
                        color='#3d3d3d'
                    />
                </div>
            
            }
        </Sidebar>  
    )
}

export default AvaliableDrivers
