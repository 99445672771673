//Este arquivo precisa ser JSX por causa da lib React Paginate que não possui suporte ao typescript.
import { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import '../../styles/paginator.css'

const Pagination = ({ quantity, onSelect, classContainer }) => {

    const [ disable, setDisable ] = useState(false)

    useEffect(()=>{
      return quantity >= 2 ? setDisable(false) : setDisable(true)
    }, [quantity])

    const handlePage = (event) => {
      onSelect(event.selected + 1) // ReactPaginate inicia no índice 0. Envia indíce + 1 para obter páginas. 
    }
      
    return (
      <ReactPaginate
        breakLabel='...'
        nextLabel='>'
        previousLabel='<'
        onPageChange={(event)=>{handlePage(event)}}
        pageRangeDisplayed={0}
        marginPagesDisplayed={1}
        pageCount={Math.floor(quantity)}
        // Classes CSS
        containerClassName={classContainer}
        pageClassName='other-button'
        activeClassName='selected-button'
        breakClassName='break'
        previousClassName={disable ? 'disable-prev-next' : 'previous-next'}
        nextClassName={disable ? 'disable-prev-next' : 'previous-next'} 
      />
    )
}

export default Pagination