import '../../styles/filters.css';
import { useState, useEffect, useContext } from "react";
import { TiposCNH, PossuiCNH, Especialidades } from '../../services/utils';
import { CandidateContext } from "../../services/context";
import apiRequest from "../../services/api";

const Filters = () => {

    const { setCandidateList, page, setQuantity } = useContext(CandidateContext)

    const [ states, setStates ] = useState([])
    const [ cities, setCities ] = useState<number>(0)
    const [ filters, setFilters ] = useState<any>([])
    const [ selectDisable, setSelectDisable ] = useState<boolean>(true)

    const [ resetState, setResetState] = useState('')
    const [ resetCity, setResetCity ] = useState('')
    const [ resetHasCnh, setResetHasCnh ] = useState('')
    const [ resetTypeCnh, setResetTypeCnh ] = useState('')
    const [ resetIntendedCnh, setResetIntendedCnh ] = useState('')
    const [ resetSpecialty, setResetSpecialty ] = useState('')

    useEffect(() => {
        apiRequest.get('/states').then((response) => {
            setStates(response.data.data)
        })
    }, [])

    useEffect(()=>{
        apiRequest.post('/candidate/filter', {
            filters: filters,
            filter_join: 'AND',
            page: page
        }).then((response)=>{
            setCandidateList(response.data.data)
            const qt = response.data.meta.total / response.data.meta.per_page
            const rest = response.data.meta.total % response.data.meta.per_page
            const total = qt + (rest > 0 ? 1 : 0)
            setQuantity(total)
        })  
    }, [page, filters])

    useEffect(()=>{
        const filter = filters.find((item: any)=> item.field === 'hasCnh')
        if(filter === undefined) return
        filter.param === '1' ? setSelectDisable(false) : setSelectDisable(true)
    }, [resetHasCnh])

    const handleFilter = (event: any) =>{
        if(filters.find((item: any) => item.field === event.target.name) === undefined){
            setFilters(filters.concat(createFilter(event)))
        }else{
            setFilters(filters.map((item: any)=>{
                if(item.field === event.target.name){
                    item = createFilter(event)
                }
                return item;
            }))
        }
    }

    const createFilter = (event: any) => ({
        field: event.target.name,
        operator: 'equals' ,
        param: event.target.value
    })

    const cleanFilter = () => { 
        setFilters([])
        setResetState('')
        setResetCity('')
        setResetHasCnh('')
        setResetTypeCnh('')
        setResetIntendedCnh('')
        setResetSpecialty('')
        setSelectDisable(true)
        setCities(0)
    }

    return (
        <div className='filters-container'>
                <select
                    name='state'
                    onChange={(event: any)=>{handleFilter(event); setCities(event.target.value); setResetState(event.target.value)}}
                    value={resetState}  
                >
                    <option disabled={true} value=''>Estado</option>
                    {states.map((state: any)=>{
                        return (
                            <option value={state.id} key={state.id}>{state.description}</option>
                        )
                    })}
                </select>
                <select
                    name='city'
                    onChange={(event: any)=>{handleFilter(event); setResetCity(event.target.value)}}
                    value={resetCity}
                >
                    <option disabled={true} value=''>Cidade</option>
                    {states.map((state: any)=>{
                        if(state.id == cities){
                            return state.city.map((city: any)=>(
                                <option value={city.id} key={city.id}>{city.description}</option>
                            ))
                        }
                        
                    })}
                </select>
                <select
                    name='hasCnh'
                    onChange={(event: any)=>{handleFilter(event); setResetHasCnh(event.target.value)}}
                    value={resetHasCnh}
                >
                    <option disabled={true} value=''>Possui Habilitação ?</option>
                    {PossuiCNH.map((cnh) => {
                        return (
                            <option value={cnh.id} key={cnh.id}>{cnh.description}</option>
                        )
                    })}
                </select>
                <select
                    name='typeCnh'
                    onChange={(event: any) => {handleFilter(event); setResetTypeCnh(event.target.value)}}
                    value={resetTypeCnh}
                    disabled={selectDisable}
                >
                    <option disabled={true} value=''>Categoria</option>
                    {TiposCNH.map((tipo: any) => {
                        return (
                            <option value={tipo.id} key={tipo.id}>{tipo.description}</option>
                        )
                    })} 
                </select>
                <select
                    name='intendedCnh'
                    onChange={(event: any) => {handleFilter(event); setResetIntendedCnh(event.target.value)}}
                    value={resetIntendedCnh}
                >
                    <option disabled={true} value=''>Habilitação Pretendida</option>
                    {TiposCNH.map((tipo: any) => {
                        return (
                            <option value={tipo.id} key={tipo.id}>{tipo.description}</option>
                        )
                    })} 
                </select>
                <select
                    name='specialtyId'
                    onChange={(event: any) => {handleFilter(event); setResetIntendedCnh(event.target.value)}}
                    value={resetSpecialty}
                    disabled={selectDisable}
                >
                    <option disabled={true} value=''>Especialidade Complementar</option>
                    {Especialidades.map((especialidade) => {
                        return (
                            <option key={especialidade.id} value={especialidade.id}>{especialidade.description}</option>
                        )
                    })}
                </select>
                <button type='button' onClick={cleanFilter} className='filters-btn'>Limpar Filtros</button>
        </div>
    )
}

export default Filters