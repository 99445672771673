import apiRequest from './api';
import { useEffect } from 'react';

export const isAuthenticated = () => {
    return getToken() !== null
}

export const TOKEN_KEY = '@fetransul_token@'

export const getToken = () => sessionStorage.getItem(TOKEN_KEY)

export const login = (token: string) => sessionStorage.setItem(TOKEN_KEY, token)

export const logout = () => {
    apiRequest.delete('/logout')
    sessionStorage.removeItem(TOKEN_KEY)
}
