import { useState } from 'react'
import { AiFillEye } from 'react-icons/ai'
import { FormatarTipoCNH, FormatarCNH, FormatarHabilitaçaoPretendida } from '../../../services/utils'
import CandidateInfo from './candidate-info'

const MobileScreen = ({ candidateList}: any) => {
    
    const [ isOpen, setIsOpen ] = useState(false)
    const [ driverInfo, setDriverInfo ] = useState({})

    const toggleModalCandidateInfo = (driverInfo: any) => {
        setIsOpen(!isOpen)
        setDriverInfo(driverInfo)
    }
    
    return (
        <div className='tabela-container'>
            <div style={{width: '90%'}}>
                <h1>Candidatos Disponíveis</h1>
            </div>
            <table className='table'>
                <thead>
                    <tr className='thead-row'>
                        <th>Nome</th>
                        <th>Habilitação Pretendida</th>
                        <th>Possui Habilitação?</th>
                        <th>Detalhes</th>
                    </tr>
                </thead>
                <tbody>
                    {candidateList.map((candidate: any)=>{
                        return(
                            <tr className='trow' key={candidate.id}>   
                                <td>{candidate.name}</td>
                                <td>{FormatarHabilitaçaoPretendida(candidate.intended_cnh)}</td>
                                <td>{FormatarCNH(candidate.cnh)}</td>
                                <td onClick={()=>{toggleModalCandidateInfo(candidate)}}>
                                    <AiFillEye size={25} color='#3d3d3d' />
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            {isOpen && <CandidateInfo candidateInfo={driverInfo} isOpen={isOpen} setIsOpen={toggleModalCandidateInfo}/>}
        </div>
    )
}

export default MobileScreen