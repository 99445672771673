import { createContext, useState } from "react";
import { IDriverListContext, IChildren, ICandidateListContext } from './types'

export const DriverContext = createContext<IDriverListContext>({} as IDriverListContext)
export const CandidateContext = createContext<ICandidateListContext>({} as ICandidateListContext)

export const DriverListProvider = ({ children }: IChildren)=> {

    const [ driverList, setDriverList ] = useState<any[]>([])
    const [ page, setPage ] = useState(1)
    const [ quantity, setQuantity ] = useState(0)

    return (
        <DriverContext.Provider value={{ driverList, setDriverList, page, setPage, quantity, setQuantity }}>
            {children}   
        </DriverContext.Provider> 
    )

}

export const CandidateListProvider = ({ children }: IChildren) => {

    const [ candidateList, setCandidateList ] = useState<any[]>([])
    const [ page, setPage ] = useState(1)
    const [ quantity, setQuantity ] = useState(0)

    return(
        <CandidateContext.Provider value={{ candidateList, setCandidateList, page, setPage, quantity, setQuantity }}>
            {children}
        </CandidateContext.Provider>
    )
}

