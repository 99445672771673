import '../../../styles/candidatos.css'
import { FormatarTelefone, FormatarCNH, FormatarTipoCNH, FormatarHabilitaçaoPretendida, FormatarEspecialidade } from '../../../services/utils'
import { Candidate, CandidateList } from '../../../services/types'

const DesktopScreen = ({ candidateList }: CandidateList) => {
    return (
        <div className='tabela-container' >
            <div style={{ width: '90%' }}>
                <h1>Candidatos Disponíveis</h1>
            </div>
            <table className='tabela'>
                <thead>
                    <tr className='thead-row'>
                        <th>Nome</th>
                        <th>Telefone</th>
                        <th>Email</th>
                        <th>Estado</th>
                        <th>Cidade</th>
                        <th>Possui Habilitação?</th>
                        <th>Categoria</th>
                        <th>Habilitação Pretendida</th>
                        <th>Especialidade</th>
                    </tr>
                </thead>
                <tbody>
                    {candidateList.map((candidate: Candidate) => {
                        return (
                            <tr className='trow' key={candidate.id}>
                                <td>{candidate.name}</td>
                                <td>{FormatarTelefone(candidate.phone)}</td>
                                <td>{candidate.email}</td>
                                <td>{candidate.state.description}</td>
                                <td>{candidate.city.description}</td>
                                <td>{FormatarCNH(candidate.cnh)}</td>
                                <td>{FormatarTipoCNH(candidate.type_cnh_id)}</td>
                                <td>{FormatarHabilitaçaoPretendida(candidate.intended_cnh)}</td>
                                <td>{FormatarEspecialidade(candidate.specialty)}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}



export default DesktopScreen