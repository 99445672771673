import '../../styles/selecao.css';
import logo3   from '../../images/logo3.png'

const Selecao = ({ toggleLogin, toggleDriverRegister, toggleFutureDriver }: any) => {
  return (
    <div>
      <div className='selecao-background'>
        <div className='selecao-div'>
          <a href='/'>
            <img src={logo3} className='login-img' alt='logo fetransul'></img>
          </a>
          <br />
          <br />
          <button onClick={toggleLogin} className='link-btn empresa-btn'>
            Área para Empresas
          </button>
          <button onClick={toggleDriverRegister} className='link-btn empresa-btn'>
            Área para Motoristas
          </button>
          <div className='politica-privacidade'>
            <p>O cumprimento das normas de proteção de dados e cumprimento da Lei 13.709/2018 é obrigatório. O arquivo com os dados e informações pessoais que o usuário inserir em nosso site, pode ser solicitado à qualquer momento, assim como a retirada, <br />através do e-mail: <b>comunicacao@fetransul.com.br</b></p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Selecao;