import { useState } from 'react'
import { AiFillEye } from 'react-icons/ai'
import { Driver, DriverList } from '../../../services/types'
import DriverInfo from './driver-info'

const MobileTable = ({ driverList }: DriverList) => {

    const [ isOpen, setIsOpen ] = useState(false)
    const [ driverInfo, setDriverInfo ] = useState({})

    const toggleModalDriverInfo = (driverInfo: Driver) => {
        setIsOpen(!isOpen)
        setDriverInfo(driverInfo)
    }

    return(
        <div className='motora-container'>
            <div style={{width: '90%'}}>
                <h1>Motoristas Disponíveis</h1>
            </div>
            <table className='table'>
                <thead>
                    <tr className='thead-row'>
                        <th>Nome</th>
                        <th>Habilitação</th>
                        <th>Detalhes</th>
                    </tr>
                </thead>
                <tbody>
                    {driverList.map((driver: Driver)=>{
                        return(
                            <tr className='trow'>   
                                <td>{driver.name}</td>
                                <td>{driver.typeCnh.description}</td>
                                <td onClick={()=>{toggleModalDriverInfo(driver)}}>
                                    <AiFillEye size={25} color='#3d3d3d' />
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            {isOpen && <DriverInfo driverInfo={driverInfo} isOpen={isOpen} setIsOpen={toggleModalDriverInfo}/>}
        </div>
    )
}

export default MobileTable