import '../../styles/cadastro-empresa.css'
import 'react-toastify/dist/ReactToastify.css';
import { useState } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import { useNavigate, Link } from 'react-router-dom';
import apiRequest from '../../services/api'
import InputMask from 'react-input-mask';
import logo3 from '../../images/logo3.png'
import useWindowDimensions from '../../hooks/useWindowDimensons';
import CompanyQuery from './register/companyQuery'
import Header from '../../generic-components/header'

const CompanyRegister = ({ toggleSelecao }: any) => {

    const navigate = useNavigate()

    const { width } = useWindowDimensions()

    const [cnpj, setCnpj] = useState('')
    const [company, setCompany] = useState<any>({})
    const [formData, setFormData] = useState<any>({})
    const [button, setButton] = useState<boolean>(false)

    const companyQuery = () => {    
        apiRequest.get(`/company/register/${cnpj}`).then((response) => { // <--- remover caracteres da mascara para consultar
            response.data.map((data: any) => {
                const info = {
                    name: data.name,
                    city: data.city.description,
                    state: data.state.description,
                    syndicate: data.syndicate.name
                }

                setCompany(info)
            })

            if (response.status === 200) {
                setButton(true)
            } 
        }).catch((error) => {
            if (error) {
                toast.error('Identificamos que sua empresa ainda não faz parte do quadro de associados de um dos sindicatos filiados ao Sistema Fetransul. Entre em contato com o sindicato patronal responsável pela sua cidade e faça parte. Caso sua empresa já seja associada, entre em contato com o seu sindicato!', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: true,
                })
            }

            setButton(false)
            setCompany({})
        })
    }

    const handleFormData = (event: any) => {
        const name = event.target.name
        formData[name] = event.target.value // Insere dentro do 'formData' com nome e valor do input. Ex: email: teste@teste.com
        setFormData(formData)
    }

    const companyRegister = (event: any) => {
        event.preventDefault()

        if (formData.password != formData.passwordConfirm) {
            toast.error('Senhas devem coincidir!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
            })
        } 

        apiRequest.post('/user/register', {...formData, cnpj }).then((response) => {
            if (response.status === 201) {
                toast.success('Usuário criado com sucesso', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                })

                setTimeout(() => {
                    navigate('/')
                }, 3000)
            }
        }).catch((error) => {
            if(error.response.status === 422){
                toast.error('Por favor, revise os dados inseridos.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                })
            } else if (error.response.status == 400) {
                toast.error('Senhas devem coincidir!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                })
            } else if (error.response.status == 404){
                toast.error(error.response.data, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                })
            } else if (error.response.status == 409){
                toast.error(error.response.data, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                })
            }
        })
    }


    return (
        <>
            <ToastContainer />

            <div className='container'>
                {width! >= 600 ?
                    <div className='bg-transp'>
                        <form className='modal background'>
                            <div className='column-flex full-wh'>
                                <div style={{ height: '35%' }} className='header'>
                                    <Header>
                                        <h2 className='title'>Cadastro para Empresas</h2>
                                    </Header>
                                </div>
                                <div style={{ display: 'flex', height: '65%'}}>
                                    <div className='modal-grid'>
                                        <div className='modal-info align-flex column-flex'>
                                            <div style={{ width: '80%', justifyContent: 'space-evenly' }} className='column-flex full-height'>
                                                <div className='full-width'>
                                                    <a className='sub-title'>1. Insira o CNPJ da empresa</a>
                                                    <InputMask
                                                        name='cnpj'
                                                        mask='99.999.999/9999-99'
                                                        type='text'
                                                        className='modal-input-cnpj'
                                                        placeholder='CNPJ'
                                                        onChange={(event) => { setCnpj(event.target.value.replace(/[^\d]+/g,'')) }}
                                                        onBlur={companyQuery}
                                                    />
                                                </div>
                                            
                                                <div className='column-flex full-width'>
                                                    <a className='sub-title'>2. Confirme as informações</a>
                                                    <span className='span'>Nome: <strong>{company.name}</strong></span>
                                                    <span className='span'>Sindicato Vinculado: <strong>{company.syndicate}</strong></span>
                                                    <span className='span'>Localidade: <strong>{company.city} - {company.state} </strong></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                    <div className='modal-grid'>
                                        <div className='modal-info align-flex column-flex'>
                                            <a className='sub-title'>3. Crie um usuário</a>
                                            <div className='usuario full-flex column-flex'>
                                                <input
                                                    name='email'
                                                    placeholder='Email'
                                                    className='modal-input'
                                                    type='email'
                                                    onChange={handleFormData}
                                                />
                                                <input
                                                    name='password'
                                                    placeholder='Senha'
                                                    className='modal-input'
                                                    type='password'
                                                    onChange={handleFormData}
                                                />
                                                <input
                                                    name='passwordConfirm'
                                                    placeholder='Confirme sua senha'
                                                    className='modal-input'
                                                    type='password'
                                                    onChange={handleFormData}
                                                />
                                                <label className='password-label'>Senha deve possuir pelo menos <b>8 caracteres</b>, incluindo <b>uma letra maiúscula, uma letra minúscula, um dígito e um caracter especial.</b></label>
                                                {button && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: '10px' }}>
                                                    <button className='submit-button ' onClick={companyRegister}>Cadastrar</button>
                                                </div>}
                                            </div>
                                        </div>
                                        <div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </form>
                    </div>
                    : <CompanyQuery />
                }
            </div>

        </>
    )
}

export default CompanyRegister