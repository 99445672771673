import { useState } from 'react'
import { AiFillCheckCircle } from 'react-icons/ai'
import { ToastContainer, toast } from 'react-toastify'
import { IErrorProps } from '../../../services/types'
import { useNavigate } from 'react-router-dom';
import apiRequest from '../../../services/api'

const CompanyRegister = ({ companyInfo }: any) => {

    const [ formData, setFormData ] = useState<any>({})
    const [ errorProps, setErrorProps ] = useState<IErrorProps>({ 
        borderStatus: '#3d3d3d',
        message: '', 
    })

    const navigate = useNavigate()

    const { cnpj } = companyInfo

    const handleFormData = (event: any) => {
        const name = event.target.name
        formData[name] = event.target.value
        setFormData({...formData, cnpj})
    }
     
    const register = (event: any) => {
        event.preventDefault()

        apiRequest.post('/user/register', formData).then((response) => {
            if(response.status === 201) {
                setErrorProps({
                    borderStatus: '#3d3d3d',
                    message: '', 
                }) 

                toast.success('Usuário criado com sucesso', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                })

                setTimeout(() => {
                    navigate('/')
                }, 3000)
            }
        }).catch((error) => {
            if (error.response.status == 409 || error.response.status == 400) {
                setErrorProps({
                    borderStatus: '#a21b1b',
                    message: error.response.data
                })    
            } else if(error.response.status === 422){
                setErrorProps({
                    borderStatus: '#a21b1b',
                    message: error.response.data.errors[0].message
                })  
            } else {
                setErrorProps({
                    borderStatus: '#a21b1b',
                    message: 'Houve algum erro. Tente novamente mais tarde'
                })
            }
        })
    }  

    return(
        <>
            <ToastContainer />
            <div className='register-container'>
                <div className='flex-div title-subtitle'>
                    <div style={{display: 'flex', alignItems:'center'}}>
                        <h2>Empresa Encontrada</h2>        
                        <AiFillCheckCircle size={25} color='#4cae50'/>
                    </div>
                    <span>Para criar um cadastro forneça um email válido e crie uma senha.</span>
                </div>
                <div className='company-info'>
                    <h4>Nome: {companyInfo.name}</h4>
                    <h4>CNPJ: {companyInfo.cnpj}</h4>
                    <h4>Sindicato vinculado: {companyInfo.syndicate}</h4>
                </div>
                <div className='flex-div' style={{height: '50%'}}>
                    <form className='register-form' onSubmit={register}>
                        <input 
                            style={{border: `2px solid ${errorProps.borderStatus}`}}
                            placeholder='Email' 
                            name='email'
                            type='email'
                            onChange={(event)=>handleFormData(event)} 
                            required
                        />                        
                        <input 
                            style={{border: `2px solid ${errorProps.borderStatus}`}}
                            placeholder='Senha(Mínimo 8 caracteres com uma letra maiúscula, uma minúscula, um dígito e um especial)' 
                            name='password'
                            type='password' 
                            onChange={(event)=>handleFormData(event)}
                            required
                        />
                        <input 
                            style={{border: `2px solid ${errorProps.borderStatus}`}}
                            placeholder='Confirme sua senha'
                            name='passwordConfirm' 
                            type='password'
                            onChange={(event)=>handleFormData(event)}
                            required
                        />
                            <div className='error-msg-container'>
                                <div className='error-msg'>{errorProps.message}</div>
                            </div>
                        <button type='submit' >Cadastrar</button>
                    </form>
                </div>
            </div>
        </> 
        
        
    )
}

export default CompanyRegister