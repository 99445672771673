import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../styles/login.css'
import apiRequest from '../../services/api';
import logo3 from '../../images/logo3.png'
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Header from '../../generic-components/header';


const Login = ({ toggleSelecao }: any) => {

    const token = '@fetransul_token@'
    const navigate = useNavigate()

    const [formData, setFormData] = useState<any>({})

    const handleFormData = (event: any) => {
        const name = event.target.name
        formData[name] = event.target.value
        setFormData(formData)
    }

    const login = (event: any) => {
        event.preventDefault()

        apiRequest.post('/login', formData).then((response) => {
            if (response.status == 200) {
                sessionStorage.setItem(token, response.data.token)
                navigate('/inicio')
            }
        }).catch((error) => {
            if (error.response.status === 400) {
                toast.error('Credenciais Inválidas', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                })
            } else if(error.response.status === 500) {
                toast.error('Houve algum erro. Aguarde um instante e tente novamente', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                })
            }
        })
    }

    return (
        <>
            <ToastContainer />
            <div className='login-background'>
                <div className='login-div'>
                    <div className='login-header header'>
                        <Header>
                            <h2 className='login-title'>Login</h2>
                        </Header>
                    </div>
                    <div style={{ height: '65%', flexDirection: 'column' }} className='full-flex'>
                        <div style={{width: '80%'}}>
                            <input
                                className='login-input'
                                placeholder='Email'
                                name='email'
                                onChange={(event) => { handleFormData(event) }}
                            />
                            <input
                                className='login-input'
                                placeholder='Senha'
                                name='password'
                                type='password'
                                onChange={handleFormData}
                            />
                        </div>
                        <button className='submit-button' style={{ marginTop: '15px'}} onClick={login}>
                            Entrar
                        </button>
                        <div className='login-cad-rec full-flex'>
                            <a>Não possui uma conta?
                                <Link to='/cadastro-empresa' className='span-cad-rec'>Cadastrar</Link>
                            </a>
                        </div>
                    </div>
                </div>

            </div>
        </>
        
    )
}

export default Login