//Esse arquivo precisa ser .JS por causa da lib 'react-responsive-sidebar' que não tem suporte ao Typescript.
import '../../styles/motoristas-candidatos-btn.css'
import '../../styles/sidebar.css'
import { SidebarItem } from 'react-responsive-sidebar'
import { Link } from 'react-router-dom'
import Filters from './candidates-filters'
import Logo from '../../images/logo3.png'
import LogoutButton from './logout-button'

export const SideBarItems = [
    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems:'center', height: '100%'}}>
            <SidebarItem
                hoverHighlight='none'
            >
                <Link to="/inicio" className='redirect-btn'>
                    <img src={Logo} alt='Fetransul Logo' className='bar-img' />
                </Link>
                
            </SidebarItem>
    
            <SidebarItem
                hoverHighlight='none'
            >
                <Filters />
            </SidebarItem>

            <SidebarItem
                hoverHighlight='none'
            >
               <div className='redirect-btn-container'>
                    <Link to="/inicio" className='redirect-btn'>Motoristas Disponíveis</Link>
                </div>
            </SidebarItem> 

            <SidebarItem
                hoverHighlight='none'
            >
                <LogoutButton />
            </SidebarItem>
    </div>
]

