import '../../styles/drop-down.css'
import { FormatarTelefone } from '../../services/utils'
import { MdAccountBox } from 'react-icons/md'

const DropDown = ({ myInfo, setDropDown }: any) => {
    return (
        <div style={{display: 'flex', justifyContent: 'flex-end' }}> 
            <div className="dropdown-container">
                <div className="dropdown-header">
                    <li className="dropdown-title"><b>Minha Conta</b>
                        <MdAccountBox size={40} color='#3d3d3d' onClick={setDropDown}/>
                    </li>
                    
                </div>
                <div style={{width: '85%'}}>
                    <ul className="dropdown-ul">
                        <div className=''>
                            <li>Nome: {myInfo.name}</li>
                            <li>CNPJ: {myInfo.cnpj}</li>
                            <li>Contato: {FormatarTelefone(myInfo.phone) }</li>
                            <li>Email: {myInfo.email  || 'Não informado'}</li>
                            <li>Sindicato: {myInfo.syndicate.name}</li>
                        </div>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default DropDown