import '../../../styles/modal-motorista.css'
import { FormatarCNH, FormatarTipoCNH, FormatarTelefone, FormatarHabilitaçaoPretendida, FormatarEspecialidade } from '../../../services/utils'
import { AiFillCloseCircle, AiFillPhone } from 'react-icons/ai'
import { ToastContainer, toast } from 'react-toastify';
import { MdEmail } from 'react-icons/md'
import Modal from 'react-modal'
import copy from 'copy-to-clipboard'

const CandidateInfo = ({ candidateInfo, isOpen, setIsOpen }) => {

    const copyToClipboard = (event) => {
        copy(event.target.value)
        toast.info('Copiado para área de transferência', {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true
        })
    }

    return (
        <>
            <Modal
                isOpen={isOpen}
                className="modal-info-motorista"
                overlayClassName="overlay"
                onRequestClose={setIsOpen}
            >
                <div className='modal-container'>
                    <ToastContainer />
                    <div className='modal-header'>
                        <h2>Informações do Candidato</h2>
                        <span onClick={setIsOpen} className='close-btn'>
                            <AiFillCloseCircle size={20} color='#fff' />
                        </span>
                    </div>
                    <div className='modal-body-full'>
                        <div className='modal-body'>
                            <div style={{ display: 'flex', width: '100%', marginBottom: '5px' }}>
                                <span className='name'><b>{candidateInfo.name}</b></span>
                            </div>
                            <h4 style={{ margin: 0, padding: 0 }}>Habilitação Pretendida: {FormatarHabilitaçaoPretendida(candidateInfo.intended_cnh)}</h4>
                            <span>Telefone: <b>{FormatarTelefone(candidateInfo.phone)}</b></span>
                            <span>Email: <b>{candidateInfo.email}</b></span>
                            <span>Possui Habilitação?: <b>{FormatarCNH(candidateInfo.cnh)}</b></span>
                            <span>Categoria: <b>{FormatarTipoCNH(candidateInfo.type_cnh_id)}</b></span>
                            <span>Especialidade: <b>{FormatarEspecialidade(candidateInfo.specialty)}</b></span>
                            <span>Estado: <b>{candidateInfo.state.description}</b></span>
                            <span>Cidade: <b>{candidateInfo.city.description}</b></span>
                        </div>

                        <div className='modal-footer'>
                            <button
                                value={candidateInfo.phone}
                                onClick={(event) => { copyToClipboard(event) }}
                            >
                                <AiFillPhone /> Copiar telefone
                            </button>
                            <button
                                value={candidateInfo.email}
                                onClick={(event) => { copyToClipboard(event) }}
                            >
                                <MdEmail /> Copiar email
                            </button>
                        </div>
                    </div>

                </div>
            </Modal>
        </>
    )
}

export default CandidateInfo