import React, { useState, useEffect } from 'react';
import apiRequest from '../../services/api';
import InputMask from 'react-input-mask';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate, Link } from 'react-router-dom';
import '../../styles/cadastro-motorista.css';
import 'react-toastify/dist/ReactToastify.css';
import logo3 from '../../images/logo3.png';
import { Especialidades, TiposCNH } from '../../services/utils';
import Header from '../../generic-components/header';

const DriverRegister = ({ toggleSelecao }: any) => {

    const [states, setStates] = useState([])
    const [city, setCity] = useState('')
    const [formData, setFormData] = useState<any>({})

    useEffect(() => {
        apiRequest.get('/states').then((response) => {
            setStates(response.data.data)
        })
    }, [])

    const handleFormData = (event: any) => {
        const name = event.target.name
        formData[name] = event.target.value
        setFormData(formData)
    }

    const driverRegister = (event: any) => {
        event.preventDefault()

        formData.cpf = formData.cpf.replace(/\-/, '').replace(/\./, '').replace(/\./, '')
        formData.phone = formData.phone.replace(/\(/, '').replace(/\)/, '').replace(/\-/, '')
        formData.stateId = parseInt(formData.stateId)
        formData.cityId = parseInt(formData.cityId)
        formData.typeCnhId = parseInt(formData.typeCnhId)
        if(!formData.email) formData.email = null

        apiRequest.post('/driver/register', formData).then((response) => {

            if (response.status === 201) {
                toast.success('Agradecemos o seu cadastro em nossa base. Mediante necessidade e demanda de contratação, as empresas parceiras do projeto poderão contatá-lo.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                })
                setTimeout(() => {
                    document.location.reload()
                }, 5000)
            } return;

        }).catch((error) => {
            if (error.status === 500) {
                toast.error('Preencha todos os campos do formulário', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                });
                return;
            }
            toast.error('Favor atualize a página e tente novamente', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
            });
            return;
        })
    }

    return (
        <>
            <ToastContainer />

            <div className='driver-background background'>
                <div className='driver-div full-flex'>
                    <div className='driver-header header'>
                        <Header>
                            <h2 className='driver-title title'>Cadastro para Motoristas</h2>
                        </Header>
                    </div>
                    <div className='form-container full-flex'>
                        <form onSubmit={driverRegister} className='form-cadastro'>
                            <input
                                className='driver-input'
                                name='name'
                                placeholder='Nome Completo'
                                onChange={handleFormData}
                                autoComplete='off'
                                required
                            />
                            <InputMask
                                className='driver-input'
                                name='phone'
                                placeholder='Telefone'
                                onChange={handleFormData}
                                mask='(99)99999-9999'
                                autoComplete='off'
                                required
                            />
                            <InputMask
                                className='driver-input'
                                name='cpf'
                                placeholder='CPF'
                                onChange={handleFormData}
                                mask='999.999.999-99'
                                autoComplete='off'
                                required
                            />                
                            <input                                         
                                className='driver-input'
                                name='birthDate'
                                placeholder='Data de Nascimento'
                                onChange={handleFormData}
                                onFocus={(e) => (e.target.type = "date")}
                                onBlur={(e) => (e.target.type = "text")}
                                aria-label='AAAA/MM/DD'
                                required
                            />
                            <input
                                className='driver-input'
                                name='email'
                                placeholder='Email (Opcional)'
                                type='email'
                                onChange={handleFormData}
                            />

                            <div className='select-div'>
                                <select
                                    name='typeCnhId'
                                    onChange={handleFormData}
                                    required
                                >
                                    <option disabled selected={true}>Categoria CNH</option>
                                    {TiposCNH.map((tipo) => (
                                        <option value={tipo.id} key={tipo.id}>{tipo.description}</option>
                                    ))}
                                </select>
                                <select
                                    name='specialtyId'
                                    onChange={handleFormData}
                                    required
                                >
                                    <option disabled selected={true}>Especialidade complementar</option>
                                    {Especialidades.map((especialidade) => (
                                        <option value={especialidade.id} key={especialidade.id}>{especialidade.description}</option>
                                    ))}
                                </select>
                            </div>
                            <div className='select-div'>
                                <select
                                    name='stateId'
                                    onChange={(event) => { setCity(event.target.value); handleFormData(event) }}
                                    required
                                >
                                    <option selected={true} disabled={true}>Estado</option>
                                    {states.map((estado: any) => (
                                        <option value={estado.id} key={estado.id}>{estado.description}</option>
                                    ))}
                                </select>
                                <select
                                    name='cityId'
                                    onChange={handleFormData}
                                    required
                                >
                                    <option selected={true} disabled={true}>Cidade</option>
                                    {states.map((state: any) => {
                                        if (state.id == city) {
                                            return state.city.map((city: any) => (
                                                <option value={city.id} key={city.id}>{city.description}</option>
                                            ))
                                        }
                                    })}
                                </select>
                            </div>
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                <button type='submit' style={{marginTop: '15px'}} className='submit-button'>Cadastrar</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>

    )
}

export default DriverRegister