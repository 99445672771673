import apiRequest from '../../services/api'

const LogoutButton = () => {

    const logout = () => {
        apiRequest.delete('/logout').then((response)=>{
            if(response.status === 200){
                sessionStorage.removeItem('@fetransul_token@')
                window.location.reload()
            }
        })
    }

    return (
        <button onClick={logout} className='filters-btn'>Logout</button>
    )

}

export default LogoutButton