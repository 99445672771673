import '../../../styles/modal-motorista.css'
import { FormatarEspecialidade, FormatarTelefone } from '../../../services/utils'
import { AiFillCloseCircle, AiFillPhone } from 'react-icons/ai'
import { MdEmail } from 'react-icons/md'
import { ToastContainer, toast } from 'react-toastify';
import Modal from 'react-modal'
import copy from 'copy-to-clipboard'

const DriverInfo = ({driverInfo, isOpen, setIsOpen}) => {

    const copyToClipboard = (event) => {
        copy(event.target.value)
        toast.info('Copiado para área de transferência', {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true
        })
    }

    return (
        <>
            <Modal
            isOpen={isOpen}
            className="modal-info-motorista"
            overlayClassName="overlay"
            onRequestClose={setIsOpen}
        
            >
                <div className='modal-container'>
                <ToastContainer />
                    <div className='modal-header'>
                        <h2>Informações do Motorista</h2>
                        <span onClick={setIsOpen} className='close-btn'>
                            <AiFillCloseCircle size={20} color='#fff'/>
                        </span>
                    </div>    
                    <div className='modal-body-full'>
                        <div className='modal-body'>
                            <div style={{display: 'flex', width: '100%', marginBottom: '5px'}}>
                                <span className='name'><b>{driverInfo.name}</b></span>
                            </div>
                            <span>Telefone: <b>{FormatarTelefone(driverInfo.phone)}</b></span>
                            <span>Email: <b>{driverInfo.email}</b></span>
                            <span>Habilitação: <b>{driverInfo.typeCnh.description}</b></span>
                            <span>Especialidade: <b>{FormatarEspecialidade(driverInfo.specialty)}</b></span>
                            <span>Estado: <b>{driverInfo.state.description}</b></span>  
                            <span>Cidade: <b>{driverInfo.city.description}</b></span>
                        </div>

                        <div className='modal-footer'>
                            <button 
                                value={driverInfo.phone}
                                onClick={(event)=>{copyToClipboard(event)}}
                            >
                                <AiFillPhone /> Copiar telefone
                            </button>
                            <button
                                value={driverInfo.email}
                                onClick={(event)=>{copyToClipboard(event)}}
                            >
                                <MdEmail /> Copiar email
                            </button>
                        </div>
                    </div>
                    
                </div>
            </Modal> 
        </>
        
              
        
    )
}

export default DriverInfo