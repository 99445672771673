import { useState, useEffect } from 'react'

interface WindowDimension {
    width: number | null,
}

const useWindowDimensions = (): WindowDimension => {

    const hasWindow = typeof window !== 'undefined'

    const getWindowDimensions = () => {
        const width: number | null = hasWindow ? window.innerWidth : null

        return {
            width, 
        }
    }

    const [ windowDimensions, setWindowDimensions ] = useState(getWindowDimensions())

    useEffect(()=>{
        if(hasWindow){
            const handleResize = () => {
                setWindowDimensions(getWindowDimensions())
            }

            window.addEventListener('resize', handleResize)
            return () => window.removeEventListener('resize', handleResize)
        }


    }, [hasWindow])

    return windowDimensions;
}
export default useWindowDimensions