import React, { useEffect, useState} from 'react'
import Login from '../components/paginaInicial/login'
import DriverRegister from '../components/paginaInicial/driver-register';
import Selecao from '../components/paginaInicial/selecao'
import '../styles/paginaInicial.css'
import '../styles/global.css'
import CompanyRegister from '../components/companies/companyRegister';
import FutureDriver from '../components/paginaInicial/futureDriver'

const Opening = () => {

    const [ login, setLogin ] = useState(false)
    const [ driverRegister, setDriverRegister ] = useState(false)
    const [ selection, setselection ] = useState(true)
    const [ companyRegister, setCompanyRegister ] = useState(false)
    const [ futureDriver, setFutureDriver] = useState(false)

    const toggleLogin = () => {
        setLogin(true)
        setDriverRegister(false)
        setselection(false)
        setCompanyRegister(false)
        setFutureDriver(false)
    }

    const toggleDriverRegister = () => {
        setLogin(false)
        setDriverRegister(true)
        setselection(false)
        setCompanyRegister(false)
        setFutureDriver(false)
    }

    const toggleSelecao = () => { 
        setLogin(false)
        setDriverRegister(false)
        setselection(true)
        setCompanyRegister(false)
        setFutureDriver(false)
    }

    const toggleCompanyRegister = () => {
        setLogin(false)
        setDriverRegister(false)
        setselection(false)
        setCompanyRegister(true)
        setFutureDriver(false)
    }

    const toggleFutureDriver = () => {
        setLogin(false)
        setDriverRegister(false)
        setselection(false)
        setCompanyRegister(false)
        setFutureDriver(true)
    }

    return(
        <div className='container'>
            <div className='bg-transp'>
                { selection && <Selecao toggleLogin={toggleLogin} toggleDriverRegister={toggleDriverRegister} toggleFutureDriver={toggleFutureDriver} /> }
                { login && <Login toggleSelecao={toggleSelecao}/> }
                { driverRegister && <DriverRegister toggleSelecao={toggleSelecao}/> }
                { companyRegister && <CompanyRegister toggleCompanyRegister={toggleCompanyRegister}/> }
                { futureDriver && <FutureDriver toggleFutureDriver={toggleFutureDriver}/> }
            </div>
        </div>
    )
}

export default Opening